import API from "@/api";

export default {
  table: params => {
    return API.requestGet({
      url: "loan/order/rejectTableList",
      params
    });
  },
  refused: params => {
    return API.requestPost({
      url: "loan/order/resetAudit",
      data: params
    });
  },
  refuseRecord: params => {
    return API.requestPost({
      url: "loan/order/rejectedRecord",
      data: params
    });
  },
};
