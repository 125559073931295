<template>
  <div class="records">
    <el-dialog
      title="已拒记录"
      width="70%"
      :visible.sync="isShow"
      :modal="false"
      :close-on-click-modal="false"
      @close="$emit('cancel')"
      @open="openDialog"
    >
      <el-table border :data="records" :row-style="{ height: 0 }" :cell-style="{ padding: '10px 0'}">
        <el-table-column prop="tempId" label="ID" align="center" width="80"></el-table-column>
        <el-table-column prop="created_at" label="时间" align="center"></el-table-column>
        <el-table-column label="结果" align="center">拒绝</el-table-column>
        <el-table-column prop="label" label="被拒原因" align="center"></el-table-column>
        <el-table-column prop="username" label="操作客服" align="center"></el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="pageSizes"
        :page-size="10"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next"
        :total="page.allNum"
        @size-change="changeSize"
        @current-change="changePage"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import service from '../api'
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    orderId:{
      type: Number,
      default: null
    }
  },
  data() {
    return {
      records: [],
      page: {},
    }
  },
  methods: {

    getList(params) {
      // 切换分页当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      return service.refuseRecord({
        orderId: this.orderId,
        page: this.currentPage,
        pageSize: 10,
      }).then(res => {
        this.page = res.page;
        res.list.map((item, index) => {
          item.tempId = (params.pageNum - 1) * this.page.pageSize + index + 1;
        });
        this.records = res.list;
      })

    },
    openDialog() {
      this.getList({ pageNum: 1 })
    }
  }
}
</script>

<style scoped>

</style>
